/* eslint-disable camelcase */
import Compressor from 'compressorjs'
import {
  format,
  formatDistance,
  isToday,
  isValid,
  parse,
  parseISO,
  differenceInMilliseconds,
  formatDistanceToNow
} from 'date-fns'
import { id } from 'date-fns/locale'
import camelCase from 'lodash/camelCase'
import eq from 'lodash/eq'
import filter from 'lodash/filter'
import keys from 'lodash/keys'
import pick from 'lodash/pick'
import snakeCase from 'lodash/snakeCase'
import toString from 'lodash/toString'
import union from 'lodash/union'
import { customAlphabet } from 'nanoid'

export const getPricingPriceList = (type = 'monthly') => {
  return [
    {
      name: 'Free',
      code: 'basic',
      price: 0,
      order: 200,
      pages: 3,
      members: 0,
      stores: 1,
      timeLimit: '31 Days',
      customDomainLimit: 0,
      courier: true,
      epayment: true,
      scalevFee: '5%',
      customDomainScalevFee: null,
      duitku: false,
      gopay: false,
      removeScalevLogo: false,
      isCourierAggregator: false,
      integration: '-',
      sharing: false,
      exportImportLp: false,
      recurringInterval: 'month'
    },
    {
      name: 'Lite',
      code:
        type === 'annually'
          ? 'lite_yearly'
          : type === 'semiAnnually'
          ? 'lite_half_yearly'
          : 'lite_monthly',
      price:
        47000 * (type === 'annually' ? 10 : type === 'semiAnnually' ? 5 : 1),
      order: 200,
      pages: 'Unlimited',
      members: 0,
      stores: 1,
      timeLimit: '180 Days',
      customDomainLimit: 1,
      courier: true,
      epayment: true,
      scalevFee: '5%',
      customDomainScalevFee: null,
      duitku: true,
      gopay: false,
      removeScalevLogo: true,
      isCourierAggregator: true,
      integration: '-',
      sharing: false,
      exportImportLp: true,
      recurringInterval:
        type === 'annually'
          ? 'year'
          : type === 'semiAnnually'
          ? 'half_year'
          : 'month'
    },
    {
      name: 'Basic',
      code:
        type === 'annually'
          ? 'starter_yearly'
          : type === 'semiAnnually'
          ? 'starter_half_yearly'
          : 'starter_monthly',
      price:
        147000 * (type === 'annually' ? 10 : type === 'semiAnnually' ? 5 : 1),
      order: 6000,
      pages: 'Unlimited',
      members: 5,
      stores: 'Unlimited',
      timeLimit: '1 Year',
      customDomainLimit: 3,
      courier: true,
      epayment: true,
      scalevFee: '5%',
      customDomainScalevFee: '1%',
      duitku: true,
      gopay: true,
      removeScalevLogo: true,
      isCourierAggregator: true,
      integration:
        'Webhook, Woowa, StarSender, Moota, Birdsend, Mailketing, Facebook (Custom Audience)',
      sharing: false,
      exportImportLp: true,
      recurringInterval:
        type === 'annually'
          ? 'year'
          : type === 'semiAnnually'
          ? 'half_year'
          : 'month'
    },
    {
      name: 'Pro',
      code:
        type === 'annually'
          ? 'pro_yearly'
          : type === 'semiAnnually'
          ? 'pro_half_yearly'
          : 'pro_monthly',
      price:
        247000 * (type === 'annually' ? 10 : type === 'semiAnnually' ? 5 : 1),
      order: 25000,
      pages: 'Unlimited',
      members: 100,
      stores: 'Unlimited',
      timeLimit: '3 Years',
      customDomainLimit: 10,
      courier: true,
      epayment: true,
      scalevFee: '5%',
      customDomainScalevFee: '1%',
      duitku: true,
      gopay: true,
      removeScalevLogo: true,
      isCourierAggregator: true,
      integration:
        'Webhook, Woowa, StarSender, Moota, Birdsend, Mailketing, Facebook (Custom Audience)',
      sharing: true,
      exportImportLp: true,
      recurringInterval:
        type === 'annually'
          ? 'year'
          : type === 'semiAnnually'
          ? 'half_year'
          : 'month'
    },
    {
      name: 'Ultimate',
      code:
        type === 'annually'
          ? 'business_yearly'
          : type === 'semiAnnually'
          ? 'business_half_yearly'
          : 'business_monthly',
      price:
        497000 * (type === 'annually' ? 10 : type === 'semiAnnually' ? 5 : 1),
      order: 'Unlimited',
      pages: 'Unlimited',
      members: 'Unlimited',
      stores: 'Unlimited',
      timeLimit: '5 Years',
      customDomainLimit: 20,
      courier: true,
      epayment: true,
      scalevFee: '5%',
      customDomainScalevFee: '1%',
      duitku: true,
      gopay: true,
      removeScalevLogo: true,
      isCourierAggregator: true,
      integration:
        'Webhook, Woowa, StarSender, Moota, Birdsend, Mailketing, Facebook (Custom Audience)',
      sharing: true,
      exportImportLp: true,
      recurringInterval:
        type === 'annually'
          ? 'year'
          : type === 'semiAnnually'
          ? 'half_year'
          : 'month'
    }
  ]
}

/**
 * Helpers
 * ===
 * ### Important Log
 * Necessary log if you really want to use logger in develop environment
 * @param {*} message - Anything that want to show in console browser / terminal
 */
export const log =
  process.env.NODE_ENV === 'production'
    ? () => {}
    : // eslint-disable-next-line no-console
      console.log.bind(console)

/**
 * Helpers
 * ===
 * ### Check Empty
 * Check emptyness of an object, array, string or even undefiend data type.
 * @param {Any} data
 */
export function isEmpty(data) {
  let result = false

  if (typeof data === 'object') {
    if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]')
      result = true
    if (!data) result = true
  } else if (typeof data === 'string') {
    if (!data.trim()) result = true
  } else if (typeof data === 'undefined') {
    result = true
  }

  return result
}

/**
 * Helpers
 * ===
 * ### Get only formatted date from raw date
 * Using date-fns as third party to reformat for display purposes.
 *
 * Raw date will be convert to 'dd MMMM yyyy'
 * @param {Date} date - Date format that accepted in date-fns
 */
export const getDate = (date) => {
  if (!isValid(parseISO(date))) return '-'
  return format(new Date(date), 'dd MMMM yyyy', { locale: id })
}

export const getMonthYear = (date) => {
  if (!isValid(parseISO(date))) return '-'
  return format(new Date(date), 'MMMM yyyy', { locale: id })
}

export const getWeekYear = (date) => {
  const splitted = date.split('-')
  return `Week ${splitted[1]} ${splitted[0]}`
}

/**
 * Helpers
 * ===
 * ### Get only formatted full date + time from raw date
 * Using date-fns as third party to reformat for display purposes.
 *
 * Raw date will be convert to 'dd MMMM yyyy, HH:mm'
 * @param {Date} date - Date format that accepted in date-fns
 */
export const getDateTime = (date) => {
  try {
    if (!isValid(parseISO(date))) return '-'
    return new Intl.DateTimeFormat('id-ID', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(new Date(date))
  } catch (err) {
    return '-'
  }
}

export const getDateTimeDetail = (date) => {
  try {
    if (!isValid(parseISO(date))) return '-'

    const formatter = new Intl.DateTimeFormat('en-CA', {
      timeZone: 'Asia/Jakarta',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    })

    const formatted = formatter.format(new Date(date))
    return `${formatted}, UTC+7`
  } catch (err) {
    return '-'
  }
}

export const getTimeAgoOrFormattedDate = (date) => {
  const currentDate = new Date()
  const timeDifference = differenceInMilliseconds(currentDate, new Date(date))

  // If the time difference is larger than a day, format the date
  if (timeDifference >= 86400000) {
    // 86400000 milliseconds in a day
    return format(new Date(date), 'dd MMM yyyy, HH:mm')
  }

  // Otherwise, generate a time ago string
  const formatOptions = { addSuffix: true, includeSeconds: true }
  return formatDistanceToNow(new Date(date), formatOptions)
}

/**
 * Helpers
 * ===
 * ### Get only formatted full date + time from raw date
 * Using date-fns as third party to reformat for display purposes.
 *
 * Raw date will be convert to 'dd/MM/yyyy, HH:mm'
 * @param {Date} date - Date format that accepted in date-fns
 */
export const getDateTimeShort = (date) => {
  if (!isValid(parseISO(date))) return '-'
  return format(new Date(date), 'dd/MM/yyyy, kk:mm', { locale: id })
}

export const getDateOnly = (date) => {
  if (!isValid(parseISO(date))) return '-'
  return format(new Date(date), 'dd/MM/yyyy', { locale: id })
}

export const getDateHuman = (date) => {
  if (!isValid(parseISO(date))) return '-'
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  const newDate = new Date(date)
  return newDate.toLocaleDateString('id', options)
}

export const getDateShort = (date) => {
  if (!isValid(parseISO(date))) return '-'
  return format(new Date(date), 'dd/MM/yy', { locale: id })
}

export const getDateShortV2 = (date) => {
  if (!isValid(parseISO(date))) return '-'
  return new Intl.DateTimeFormat('id-ID', {
    year: '2-digit',
    month: 'short',
    day: '2-digit'
  })
    .format(new Date(date))
    .replace(/^0/, '')
}

/**
 * Helpers
 * ===
 * ### Get only formatted full date or today + time from raw date
 * Using date-fns as third party to reformat for display purposes.
 *
 * Raw date will be convert to 'dd MMMM yyyy, HH:mm'
 * If checkToday TRUE, Raw dte will be convert to 'Today - HH:mm'
 * @param {Date} date - Date format that accepted in date-fns
 */
export const getDateToday = (date) => {
  if (!isValid(parseISO(date))) return '-'
  const today = isToday(new Date(date))
  const formatted = format(new Date(date), 'dd MMMM yyyy', { locale: id })
  if (today) return 'Today'
  return formatted
}

/**
 * Helpers
 * ===
 * ### Get only formatted full date or today + time from raw date
 * Using date-fns as third party to reformat for display purposes.
 *
 * Raw date will be convert to 'dd MMMM yyyy, HH:mm'
 * If checkToday TRUE, Raw dte will be convert to 'Today - HH:mm'
 * @param {Date} date - Date format that accepted in date-fns
 */
export const getDateTimeToday = (date) => {
  if (!isValid(parseISO(date))) return '-'
  const today = isToday(new Date(date))
  const formatted = format(
    new Date(date),
    today ? 'kk:mm' : 'dd MMMM yyyy, kk:mm',
    { locale: id }
  )
  if (today) return `Today, ${formatted}`
  return formatted
}

/**
 * Helpers
 * ===
 * ### Get only formatted time from raw date
 * Using date-fns as third party to reformat for display purposes.
 *
 * Raw date will be convert to 'HH:mm'
 * @param {Date} date - Date format that accepted in date-fns
 */
export const getTime = (date) => {
  if (!isValid(parseISO(date))) return '-'
  return format(new Date(date), 'kk:mm', { locale: id })
}

export const timeDuration = (dateToCompare, date) => {
  if (!isValid(parseISO(dateToCompare)) || !isValid(parseISO(date))) return '-'
  return formatDistance(new Date(dateToCompare), new Date(date), {
    includeSeconds: true,
    locale: id
  })
}

/**
 * Helpers
 * ===
 * ### Remap Property key
 * Remap The Object Property to `SET_[property]` (PASCAL ALL CAPS) for to be used on mutations
 *
 * @param {Object} state - The state to remapped
 * @param {Array} string - Selected Array of String state name want to remap
 */
export function mapMutationsHelper(stateObj, selected) {
  const ret = selected ? pick(stateObj, selected) : stateObj
  // eslint-disable-next-line array-callback-return
  Object.keys(ret).map((key) => {
    ret[`SET_${snakeCase(key)}`.toUpperCase()] = (state, payload) => {
      state[key] = payload
    }
    delete ret[key]
  })
  return ret
}

/**
 * Helpers
 * ===
 * ### Remap Property key
 * Remap The Object Property to `set[property]` (camelCase) for to be used on getters
 *
 * @param {Object} state - The state to remapped
 * @param {Array} string - Selected Array of String state name want to remap
 */
export const mapGettersHelper = (stateObj, selected) => {
  const ret = selected ? pick(stateObj, selected) : stateObj
  // eslint-disable-next-line array-callback-return
  Object.keys(ret).map((key) => {
    ret[camelCase(`get ${key}`)] = (state) => state[key]
    delete ret[key]
  })
  return ret
}

/**
 * Utilities
 * ===
 * ### Accept Only Number on keypress
 * Prevent user to input other than number
 * @param {Event} evt - Event input
 */
export const onlyNumberOnKeypress = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    if (charCode === 43) {
      return true
    }
    evt.preventDefault()
  }
  return true
}

/**
 * Helpers
 * ===
 * ### Convert String to Slug
 * Converting String to Slug
 * @param {String} text - Value that need to be converted
 */
export const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\\-]+/g, '') // Remove all non-word chars
    .replace(/\\-\\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text

/**
 * Helpers
 * ===
 * ### Decode Query Param
 * Convert Query Params String to Data Object with keys
 * @param {String} queryString -  Query Params String that want to converted to Data object
 */
export const decodeQueryData = (queryString) => {
  const res = {}
  const keys = queryString?.split('&')
  if (keys?.length > 0) {
    keys.forEach((query) => {
      const [queryKey, queryVal] = query.split('=')
      if (queryKey && queryVal) {
        res[queryKey] = queryVal
      }
    })
  }
  return res
}

/**
 * Helpers
 * ===
 * ### Encode Query Param
 * Convert object with keys to query params
 * @param {Object} data - Data object that want to converted to query param string
 */
export const encodeQueryData = (data) => {
  const res = []
  const keys = Object.keys(data).map((key) => key)
  keys.forEach((d) => {
    if (d && !isEmpty(data[d])) {
      res.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`)
    }
  })

  return res.join('&')
}

/**
 * Helpers
 * ===
 * ### Mapping Currency
 * Mapping currency for display purposes.
 *
 * e.g. Rupiah: Rp. 50.000 => { preifx: 'Rp', separator: '.' }
 * @param {String|Number} value - Value to be converted
 * @param {Object} cfg - Configuration accept: { prefix, separator }
 */
export const mappingCurrency = (value, cfg) => {
  if (!!value || value === 0) {
    let num = parseInt(value, 10)
    let negSym = ''
    if (num < 0) {
      num = num * -1
      negSym = '-'
    }
    const res = num
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${(cfg && cfg.separator) || '.'}`)
      .toString()

    return (
      (cfg && cfg.prefix ? `${negSym}${cfg.prefix} ` : `${negSym}Rp `) + res
    )
  }

  return ''
}

export const mappingCurrencyDynamic = (num) => {
  const absNum = Math.abs(num)
  const sign = num < 0 ? '-' : ''
  let formattedNum

  if (absNum >= 1e9) {
    formattedNum =
      (absNum / 1e9).toLocaleString('id-ID', { maximumFractionDigits: 2 }) +
      ' miliar'
  } else if (absNum >= 1e6) {
    formattedNum =
      (absNum / 1e6).toLocaleString('id-ID', { maximumFractionDigits: 2 }) +
      ' juta'
  } else if (absNum >= 1e3) {
    formattedNum =
      (absNum / 1e3).toLocaleString('id-ID', { maximumFractionDigits: 2 }) +
      ' ribu'
  } else {
    formattedNum = absNum.toLocaleString('id-ID', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  return `${sign}Rp ${formattedNum}`
}

/**
 * Helpers
 * ===
 * ### Mapping Thousand
 * Mapping thousand for display purposes.
 *
 * e.g. 50.000 => { separator: '.' }
 * @param {String|Number} value - Value to be converted
 * @param {Object} cfg - Configuration accept: { separator }
 */
export const mappingThousand = (value, cfg) => {
  let defaultDecimalComma = '00'
  if (!!value || value === 0) {
    const [valueNumber, decimalComma] = value.toString().split('.')
    defaultDecimalComma = decimalComma || defaultDecimalComma
    const num = parseInt(valueNumber, 10)
    const res = num
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${(cfg && cfg.separator) || '.'}`)
      .toString()
    return cfg && cfg.decimal ? `${res},${defaultDecimalComma}` : res
  }

  return ''
}

/**
 * Formats a number with thousand separators and optional decimal places.
 *
 * @param {number|string} value - The number to format. Can be a number or a string that can be converted to a number.
 * @param {Object} [config={}] - Configuration options for formatting.
 * @param {string} [config.separator=','] - The separator to use for thousands. Default is ','.
 * @param {boolean} [config.decimal=false] - Whether to include decimal places in the formatted output. Default is false.
 * @param {number} [config.decimalPlaces=2] - The number of decimal places to include if `decimal` is true. Default is 2.
 * @returns {string} The formatted number as a string. Returns an empty string if the input value is null, undefined, or not a valid number.
 */
export const formatThousand = (value, config = {}) => {
  const { separator = ',', decimal = false, decimalPlaces = 2 } = config

  if (value === null || value === undefined) {
    return ''
  }

  // Convert to number and handle decimal places
  const num = Number(value)
  if (isNaN(num)) return ''

  const parts = num.toFixed(decimalPlaces).split('.')

  // Format the integer part with separators
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator)

  // Return with or without decimal part
  return decimal ? parts.join('.') : parts[0]
}

/**
 * Helpers
 * ===
 * ### Mapping Thousand
 * Mapping thousand for display purposes.
 *
 * e.g. 50.000 => { separator: '.' }
 * @param {String|Number} value - Value to be converted
 * @param {Object} cfg - Configuration accept: { separator }
 */
export const mappingThousandDecimal = (value) =>
  mappingThousand(value, { decimal: true })

/**
 * Helpers
 * ===
 * ### Calculate Price After Tax
 * Sum price with given percentage tax amount .
 *
 * e.g. 110000 => SumPriceAfterTax(100000, 10)
 * @param {Number} price - Value to be converted
 * @param {Number} tax - Tax percentage
 */
export const sumPriceAfterTax = (price, tax) => {
  let priceAt = price ? parseInt(price, 10) : 0
  if (price && tax > 0) {
    priceAt += Math.round(priceAt * tax) / 100
  }
  return parseFloat(priceAt).toFixed(2)
}

/**
 * Helpers
 * ===
 * ### Decrease Price After Tax to price only
 * Decrease price after tax with given percentage tax amount .
 *
 * e.g. 100000 => SumPriceAfterTax(110000, 10)
 * @param {Number} priceAt - Value price after tax to be converted
 * @param {Number} tax - Tax percentage
 */
export const decreasePriceTax = (priceAt, tax) => {
  let price = priceAt ? parseFloat(priceAt, 10) : 0
  if (priceAt && tax > 0) {
    price = Math.round((price / (1 + tax / 100)) * 100) / 100
  }
  return parseFloat(price).toFixed(2)
}

/**
 * Helpers
 * ===
 * ### Get File Url
 * Destruct and get extension and file url from a single file.
 *
 * @param {File} file - Any file
 */
export const fileToUrl = (file) => {
  if (!file) return null
  const fileNameExt = [...file.name.split('.')].pop()
  return {
    ext: fileNameExt,
    // eslint-disable-next-line no-undef
    url: (window?.URL || webkitURL).createObjectURL(file)
  }
}

/**
 * Helpers
 * ===
 * ### Download Single Blob File
 * Create a link and download blob file from API.
 *
 * @param {Object} res - Response object from API
 * @param {String} filename - Filename with extension, it will be only file without any extension
 */
export const downloadBlob = (res, filename) => {
  if (!res) return
  const blobData = [res.data]
  const blob = new Blob(blobData, { type: 'application/octet-stream' })
  const link = document.createElement('a')
  // eslint-disable-next-line no-undef
  link.href = (window?.URL || webkitURL).createObjectURL(blob)
  link.setAttribute('download', filename || 'file')
  document.body.appendChild(link)
  link.click()
}

/**
 * Helpers
 * ===
 * ### Detect Wether Object Edited Or Not
 * Compare between 2 Object then return edited object property name (Array).
 *
 * @param {Object} oldObject - Older Unedited Object
 * @param {Object} newObject - Latest Object that need to compare
 */
export const isObjEdited = (oldObject, newObject) => {
  const keysNa = union(keys(oldObject), keys(newObject))
  return filter(keysNa, function (key) {
    return !eq(toString(oldObject[key]), toString(newObject[key]))
  })
}

/**
 * Helpers
 * ===
 */
export const getNameWithOptionsVariant = ({
  productName,
  variantOption1,
  variantOption2,
  variantOption3
}) => {
  const variantsJoined = [variantOption1, variantOption2, variantOption3]
    .filter((val) => !!val)
    .join(' - ')
  return (
    `${productName}` + (productName && variantsJoined && ' - ') + variantsJoined
  )
}

export const popperHideArrow = {
  name: 'applyArrowHide',
  enabled: true,
  phase: 'write',
  fn({ state }) {
    const { arrow } = state.elements
    if (arrow) {
      arrow.classList.add('hidden')
    }
  }
}

export const popperSameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
  }
}

export const fileToBase64 = async (file) => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })
}

export const imageCompressor = async (
  imageFile,
  isForceJpg = false,
  ignoreQuality = false
) => {
  let isWebPSupported = false

  const canvas = document.createElement('canvas')
  if (!canvas.toDataURL) {
    isWebPSupported = false
  }
  isWebPSupported =
    canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0

  return await new Promise((resolve, reject) => {
    /* eslint-disable no-new */
    new Compressor(imageFile, {
      quality: !ignoreQuality ? (isWebPSupported ? 0.9 : 0.6) : 1,
      maxWidth: 640,
      mimeType: isForceJpg
        ? 'image/jpeg'
        : isWebPSupported
        ? 'image/webp'
        : 'image/jpeg',
      convertSize: 'infinity',
      success(result) {
        resolve(new File([result], `${new Date().getTime()}-${result.name}`))
      },
      error(err) {
        reject(err)
      }
    })
  })

  // const options = {
  //   initialQuality: 0.5
  // }

  // const blob = await imageCompression(imageFile, options)
  // return new File([blob], `${new Date().getTime()}-${blob.name}`)

  // return await new Promise((resolve) => {
  //   const image = new Image()
  //   image.onload = () => {
  //     const canvas = document.createElement('canvas')
  //     canvas.width = image.naturalWidth
  //     canvas.height = image.naturalHeight
  //     canvas.getContext('2d').drawImage(image, 0, 0)
  //     canvas.toBlob((blob) => {
  //       // Now we have a `blob` containing webp data

  //       // Use the file rename trick to turn it back into a file
  //       const myImage = new File([blob], 'my-new-name.webp', {
  //         type: blob.type
  //       })
  //       resolve(myImage)
  //     }, 'image/webp')
  //   }
  //   image.src = URL.createObjectURL(imageFile)
  // })
}

export const faviconCompressor = async (favicon) => {
  if (['image/vnd.microsoft.icon'].includes(favicon.type)) {
    return favicon
  }

  return await new Promise((resolve, reject) => {
    /* eslint-disable no-new */
    new Compressor(favicon, {
      maxWidth: 48,
      maxHeight: 48,
      mimeType: 'image/png',
      success(result) {
        resolve(new File([result], `${new Date().getTime()}-${result.name}`))
      },
      error(err) {
        reject(err)
      }
    })
  })
}

/**
 * Helpers
 * ===
 * ### Initiate Query Params for API
 * Check emptyness of an query params object. if empty, set default params and assign given query parameter if exist.
 * @param {Object} queryParams
 */
export function initQueryParams(queryParams) {
  const { page, page_size, ...restQuery } = queryParams
  const query = {
    page: page || 1,
    page_size: page_size || 25
  }
  return {
    ...query,
    ...restQuery
  }
}

export function initQueryParamsCursor(queryParams) {
  const { page_size, ...restQuery } = queryParams
  const query = {
    page_size: page_size || 25
  }
  return {
    ...query,
    ...restQuery
  }
}

/**
 * Helpers
 * ===
 * ### Callback change Company Business Profile
 * Fuction for change company business profile and some logical check for permission.
 */
export async function onChangeCompanyBusiness() {
  if (!this.getPagePermission) {
    if (this.$route.path === '/order') {
      const queries = {
        ...this.$route.query
      }
      delete queries.warehouse_id
      delete queries.product_id
      delete queries.store_id
      delete queries.advertiser_id
      delete queries.business_id
      delete queries.handler_id
      window.location.href = `${this.$route.path}?${encodeQueryData(queries)}`
    } else if (this.$route.path === '/dashboard') {
      const queries = {
        ...this.$route.query
      }
      delete queries.store
      if (localStorage.getItem('dashboardQuery')) {
        const dashboardQuery = {
          ...JSON.parse(localStorage.getItem('dashboardQuery'))
        }
        delete dashboardQuery.store
        localStorage.setItem('dashboardQuery', JSON.stringify(dashboardQuery))
      }
      window.location.href = `${this.$route.path}?${encodeQueryData(queries)}`
    } else {
      window.location.href = this.$route.fullPath
    }
  } else {
    const hasPermission = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: this.getPagePermission }
    )
    const locationHref = hasPermission ? this.$route.fullPath : '/dashboard'
    if (locationHref === '/order') {
      const queries = {
        ...this.$route.query
      }
      delete queries.warehouse_id
      delete queries.product_id
      delete queries.store_id
      delete queries.advertiser_id
      delete queries.business_id
      delete queries.handler_id
      window.location.href = `${this.$route.path}?${encodeQueryData(queries)}`
    } else if (locationHref === '/dashboard') {
      const queries = {
        ...this.$route.query
      }
      delete queries.store
      if (localStorage.getItem('dashboardQuery')) {
        const dashboardQuery = {
          ...JSON.parse(localStorage.getItem('dashboardQuery'))
        }
        delete dashboardQuery.store
        localStorage.setItem('dashboardQuery', JSON.stringify(dashboardQuery))
      }
      window.location.href = `${this.$route.path}?${encodeQueryData(queries)}`
    } else {
      window.location.href = locationHref
    }
  }
}

export const redirectIfNotFound = (err, redirect) => {
  if (!err.response) {
    return undefined
  }
  return redirect('/dashboard?denied=1')
}

export const formatDateUTCISOString = (
  dateString,
  dateFormat = 'dd/MM/yyyy',
  date = new Date()
) => {
  const parsedDate = parse(dateString, dateFormat, date)
  if (!isValid(parsedDate)) return '-'
  return parsedDate.toISOString()
}

export const substractWeeksFromDate = (numOfWeeks, date = new Date()) => {
  date.setDate(date.getDate() - numOfWeeks * 7)
  return date
}

export const getPaymentMethodText = (pm) => {
  switch (pm) {
    case 'bank_transfer':
      return 'Bank Transfer'
    case 'cod':
      return 'COD'
    case 'pg':
      return 'Payment Gateway'
    case 'marketplace':
      return 'Marketplace'
    case 'no_payment':
      return 'No Payment'
    case 'card':
      return 'Card'
    case 'gopay':
      return 'GoPay'
  }
}

export const getStatusText = (status) => {
  switch (status) {
    case 'draft':
      return 'Created'
    case 'pending':
      return 'Pending'
    case 'confirmed':
      return 'Confirmed'
    case 'in_process':
      return 'Processing'
    case 'ready':
      return 'Ready to Ship'
    case 'canceled':
      return 'Canceled'
    case 'shipped':
      return 'Shipped'
    case 'completed':
      return 'Completed'
    case 'shipped_rts':
      return 'Shipped (RTS)'
    case 'rts':
      return 'RTS'
    case 'closed':
      return 'Closed'
    case 'digital_file':
      return 'Digital Product Delivery'
  }
}

export const getEmailStatusText = (status) => {
  switch (status) {
    case 'created':
      return 'Email created'
    case 'accepted':
      return 'Pending to be sent'
    case 'delivered':
      return 'Delivered to customer'
    case 'permanent_fail':
      return 'Failed'
    case 'temporary_fail':
      return 'Failed (Temporary)'
  }
}

export const getPaymentStatusText = (paymentStatus) => {
  switch (paymentStatus) {
    case 'unpaid':
      return 'Unpaid'
    case 'paid':
      return 'Paid'
    case 'conflict':
      return 'Conflict'
    case 'settled':
      return 'Settled'
  }
}

export const copyToClipboard = async (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(text)
  } else {
    const textArea = document.createElement('textarea')
    textArea.value = text
    textArea.style.position = 'fixed'
    textArea.style.left = '-999999px'
    textArea.style.top = '-999999px'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    return await new Promise((resolve, reject) => {
      document.execCommand('copy') ? resolve() : reject(new Error('error copy'))
      textArea.remove()
    })
  }
}

export const spellNumberInIndonesian = (number) => {
  const spellBeforeTwelve = [
    '',
    'SATU',
    'DUA',
    'TIGA',
    'EMPAT',
    'LIMA',
    'ENAM',
    'TUJUH',
    'DELAPAN',
    'SEMBILAN',
    'SEPULUH',
    'SEBELAS'
  ]
  if (number < 12) return spellBeforeTwelve[number]
  else if (number < 20) return `${spellNumberInIndonesian(number - 10)} BELAS`
  else if (number < 100)
    return `${spellNumberInIndonesian(
      Math.floor(parseInt(number) / 10)
    )} PULUH ${spellNumberInIndonesian(parseInt(number) % 10)}`
  else if (number < 200)
    return `SERATUS ${spellNumberInIndonesian(parseInt(number) - 100)}`
  else if (number < 1000)
    return `${spellNumberInIndonesian(
      Math.floor(number / 100)
    )} RATUS ${spellNumberInIndonesian(number % 100)}`
  else if (number < 2000)
    return `SERIBU ${spellNumberInIndonesian(number - 1000)}`
  else if (number < 1000000)
    return `${spellNumberInIndonesian(
      Math.floor(number / 1000)
    )} RIBU ${spellNumberInIndonesian(number % 1000)}`
  else if (number < 1000000000) {
    return `${spellNumberInIndonesian(
      Math.floor(number / 1000000)
    )} JUTA ${spellNumberInIndonesian(number % 1000000)}`
  } else if (number < 1000000000000) {
    return `${spellNumberInIndonesian(
      Math.floor(number / 1000000000)
    )} MILYAR ${spellNumberInIndonesian(number % 1000000000)}`
  } else if (number < 1000000000000000) {
    return `${spellNumberInIndonesian(
      Math.floor(number / 1000000000000)
    )} TRILYUN ${spellNumberInIndonesian(number % 1000000000000)}`
  }
}

export const convertLinkToPublicForm = (
  customDomains,
  subdomain,
  slug,
  envApp
) => {
  // if customDomains is empty or null, use default baseUrl
  if (!customDomains || customDomains.length === 0) {
    let baseUrl = ''
    if (envApp === 'development') {
      baseUrl = 'dev.myscalev.com'
    } else {
      baseUrl = 'myscalev.com'
    }
    return `https://${subdomain}.${baseUrl}/${slug}`
  }
  const domain = customDomains[0]
  return `https://${domain.full_url}/${slug}`
}

export const base64ToFile = (base64String, filename) => {
  const [metadata, base64Data] = base64String.split(',')
  const mimeType = metadata.match(/:(.*?);/)[1]
  const binaryData = atob(base64Data)
  const arrayBuffer = new Uint8Array(binaryData.length)
  for (let i = 0; i < binaryData.length; i++) {
    arrayBuffer[i] = binaryData.charCodeAt(i)
  }
  const blob = new Blob([arrayBuffer], { type: mimeType })
  const file = new File([blob], filename, { type: mimeType })
  return file
}

export const generateOrderPaymentStatusOptions = (
  currentStatus = null,
  allowedPaymentStatusesTo
) => {
  return [
    currentStatus === 'paid' ||
    !currentStatus ||
    allowedPaymentStatusesTo.includes('paid')
      ? {
          text: 'Paid',
          value: 'paid'
        }
      : undefined,
    currentStatus === 'unpaid' ||
    !currentStatus ||
    allowedPaymentStatusesTo.includes('unpaid')
      ? {
          text: 'Unpaid',
          value: 'unpaid'
        }
      : undefined,
    currentStatus === 'conflict'
      ? {
          text: 'Conflict',
          value: 'conflict'
        }
      : undefined,
    currentStatus === 'settled'
      ? {
          text: 'Settled',
          value: 'settled'
        }
      : undefined
  ]
}

export const generateOrderStatusOptions = (
  currentStatus = null,
  allowedStatusesTo = [],
  isCanceledHidden = false,
  isPendingHidden = false
) => {
  return [
    currentStatus === 'draft' || !currentStatus
      ? {
          value: 'draft',
          text: 'Created'
        }
      : undefined,
    (!isPendingHidden || currentStatus === 'pending') &&
    (currentStatus === 'pending' ||
      ((currentStatus === 'confirmed' ||
        currentStatus === 'in_process' ||
        currentStatus === 'ready' ||
        currentStatus === 'shipped' ||
        currentStatus === 'completed') &&
        allowedStatusesTo.includes('pending')) ||
      !currentStatus)
      ? {
          value: 'pending',
          text: 'Pending'
        }
      : undefined,
    currentStatus === 'confirmed' ||
    ((currentStatus === 'pending' ||
      currentStatus === 'in_process' ||
      currentStatus === 'ready' ||
      currentStatus === 'shipped' ||
      currentStatus === 'completed') &&
      allowedStatusesTo.includes('confirmed')) ||
    !currentStatus
      ? {
          value: 'confirmed',
          text: 'Confirmed'
        }
      : undefined,
    currentStatus === 'in_process' ||
    ((currentStatus === 'pending' ||
      currentStatus === 'confirmed' ||
      currentStatus === 'ready' ||
      currentStatus === 'shipped' ||
      currentStatus === 'completed') &&
      allowedStatusesTo.includes('in_process')) ||
    !currentStatus
      ? {
          value: 'in_process',
          text: 'Processing'
        }
      : undefined,
    currentStatus === 'ready' ||
    ((currentStatus === 'pending' ||
      currentStatus === 'confirmed' ||
      currentStatus === 'in_process' ||
      currentStatus === 'shipped' ||
      currentStatus === 'completed') &&
      allowedStatusesTo.includes('ready')) ||
    !currentStatus
      ? {
          value: 'ready',
          text: 'Ready to Ship'
        }
      : undefined,
    currentStatus === 'shipped' ||
    ((currentStatus === 'pending' ||
      currentStatus === 'confirmed' ||
      currentStatus === 'in_process' ||
      currentStatus === 'ready' ||
      currentStatus === 'completed' ||
      currentStatus === 'rts' ||
      currentStatus === 'closed') &&
      allowedStatusesTo.includes('shipped')) ||
    !currentStatus
      ? {
          value: 'shipped',
          text: 'Shipped'
        }
      : undefined,
    currentStatus === 'completed' ||
    ((currentStatus === 'confirmed' ||
      currentStatus === 'in_process' ||
      currentStatus === 'readyToShip' ||
      currentStatus === 'shipped') &&
      allowedStatusesTo.includes('completed')) ||
    !currentStatus
      ? {
          value: 'completed',
          text: 'Completed'
        }
      : undefined,
    currentStatus === 'shipped_rts' || !currentStatus
      ? {
          value: 'shipped_rts',
          text: 'Shipped (RTS)'
        }
      : undefined,
    currentStatus === 'rts' ||
    ((currentStatus === 'shipped' || currentStatus === 'shipped_rts') &&
      allowedStatusesTo.includes('rts')) ||
    !currentStatus
      ? {
          value: 'rts',
          text: 'RTS'
        }
      : undefined,
    currentStatus === 'closed' ||
    (currentStatus === 'shipped' && allowedStatusesTo.includes('closed')) ||
    !currentStatus
      ? {
          value: 'closed',
          text: 'Closed'
        }
      : undefined,
    (!isCanceledHidden || currentStatus === 'canceled') &&
    (currentStatus === 'canceled' ||
      ((currentStatus === 'draft' ||
        currentStatus === 'pending' ||
        currentStatus === 'confirmed' ||
        currentStatus === 'in_process' ||
        currentStatus === 'ready') &&
        allowedStatusesTo.includes('canceled')) ||
      !currentStatus)
      ? {
          value: 'canceled',
          text: 'Canceled'
        }
      : undefined
  ]
}

export const mapOrderPlatform = (platform) => {
  switch (platform) {
    case 'tiktokshop':
      return 'TikTok Shop'
    case 'shopee':
      return 'Shopee'
    case 'tokopedia':
      return 'Tokopedia'
    case 'scalev':
      return 'Scalev'
    case 'lazada':
      return 'Lazada'
    case 'blibli':
      return 'Blibli'
    case 'bukalapak':
      return 'Bukalapak'
    case 'mogawe':
      return 'Mogawe'
    default:
      return platform
  }
}

export const orderPlatformOptions = () => {
  return [
    {
      text: 'TikTok Shop',
      value: 'tiktokshop'
    },
    {
      text: 'Shopee',
      value: 'shopee'
    },
    {
      text: 'Tokopedia',
      value: 'tokopedia'
    },
    {
      text: 'Scalev',
      value: 'scalev'
    },
    {
      text: 'Lazada',
      value: 'lazada'
    },
    {
      text: 'Blibli',
      value: 'blibli'
    },
    {
      text: 'Bukalapak',
      value: 'bukalapak'
    },
    {
      text: 'Mogawe',
      value: 'mogawe'
    }
  ]
}

export const adSourceOptions = () => {
  return [
    {
      text: 'Organic',
      value: 'organic'
    },
    {
      text: 'Facebook',
      value: 'facebook'
    },
    {
      text: 'Tiktok',
      value: 'tiktok'
    },
    {
      text: 'Google',
      value: 'google'
    }
  ]
}

export const getFbEStandardEvents = (env) => {
  switch (env) {
    case 'development':
      return [
        {
          id: 1,
          event_name: 'AddPaymentInfo'
        },
        {
          id: 2,
          event_name: 'AddToCart'
        },
        {
          id: 3,
          event_name: 'InitiateCheckout'
        },
        {
          id: 4,
          event_name: 'Lead'
        },
        {
          id: 6,
          event_name: 'Purchase'
        },
        {
          id: 5,
          event_name: 'ViewContent'
        }
      ]

    case 'staging':
      return [
        {
          id: 1,
          event_name: 'AddPaymentInfo'
        },
        {
          id: 2,
          event_name: 'AddToCart'
        },
        {
          id: 3,
          event_name: 'InitiateCheckout'
        },
        {
          id: 4,
          event_name: 'Lead'
        },
        {
          id: 6,
          event_name: 'Purchase'
        },
        {
          id: 5,
          event_name: 'ViewContent'
        }
      ]

    case 'production':
      return [
        {
          id: 1,
          event_name: 'AddPaymentInfo'
        },
        {
          id: 2,
          event_name: 'AddToCart'
        },
        {
          id: 3,
          event_name: 'InitiateCheckout'
        },
        {
          id: 4,
          event_name: 'Lead'
        },
        {
          id: 6,
          event_name: 'Purchase'
        },
        {
          id: 5,
          event_name: 'ViewContent'
        }
      ]

    default:
      return []
  }
}

export const getTiktokStandardEvents = (env) => {
  switch (env) {
    case 'development':
      return [
        {
          id: 1,
          event_name: 'AddPaymentInfo'
        },
        {
          id: 2,
          event_name: 'AddToCart'
        },
        {
          id: 3,
          event_name: 'AddToWishlist'
        },
        {
          id: 4,
          event_name: 'ClickButton'
        },
        {
          id: 5,
          event_name: 'CompletePayment'
        },
        {
          id: 6,
          event_name: 'CompleteRegistration'
        },
        {
          id: 7,
          event_name: 'Contact'
        },
        {
          id: 8,
          event_name: 'Download'
        },
        {
          id: 9,
          event_name: 'InitiateCheckout'
        },
        {
          id: 10,
          event_name: 'PlaceAnOrder'
        },
        {
          id: 11,
          event_name: 'Search'
        },
        {
          id: 12,
          event_name: 'SubmitForm'
        },
        {
          id: 13,
          event_name: 'Subscribe'
        },
        {
          id: 14,
          event_name: 'ViewContent'
        }
      ]

    case 'staging':
      return [
        {
          id: 1,
          event_name: 'AddPaymentInfo'
        },
        {
          id: 2,
          event_name: 'AddToCart'
        },
        {
          id: 3,
          event_name: 'AddToWishlist'
        },
        {
          id: 4,
          event_name: 'ClickButton'
        },
        {
          id: 5,
          event_name: 'CompletePayment'
        },
        {
          id: 6,
          event_name: 'CompleteRegistration'
        },
        {
          id: 7,
          event_name: 'Contact'
        },
        {
          id: 8,
          event_name: 'Download'
        },
        {
          id: 9,
          event_name: 'InitiateCheckout'
        },
        {
          id: 10,
          event_name: 'PlaceAnOrder'
        },
        {
          id: 11,
          event_name: 'Search'
        },
        {
          id: 12,
          event_name: 'SubmitForm'
        },
        {
          id: 13,
          event_name: 'Subscribe'
        },
        {
          id: 14,
          event_name: 'ViewContent'
        }
      ]

    case 'production':
      return [
        {
          id: 1,
          event_name: 'AddPaymentInfo'
        },
        {
          id: 2,
          event_name: 'AddToCart'
        },
        {
          id: 3,
          event_name: 'AddToWishlist'
        },
        {
          id: 4,
          event_name: 'ClickButton'
        },
        {
          id: 5,
          event_name: 'CompletePayment'
        },
        {
          id: 6,
          event_name: 'CompleteRegistration'
        },
        {
          id: 7,
          event_name: 'Contact'
        },
        {
          id: 8,
          event_name: 'Download'
        },
        {
          id: 9,
          event_name: 'InitiateCheckout'
        },
        {
          id: 10,
          event_name: 'PlaceAnOrder'
        },
        {
          id: 11,
          event_name: 'Search'
        },
        {
          id: 12,
          event_name: 'SubmitForm'
        },
        {
          id: 13,
          event_name: 'Subscribe'
        },
        {
          id: 14,
          event_name: 'ViewContent'
        }
      ]

    default:
      return []
  }
}

export const remapEventParametersKeys = (eventParameters, standardEvents) => {
  const remappedObj = {}
  Object.keys(eventParameters).forEach((k) => {
    // if the format of the key is id of that event, we have to find the event name using the id
    if (standardEvents.some((x) => x.id === k)) {
      const eventName = standardEvents.find((x) => x.id === k).event_name
      remappedObj[eventName] = eventParameters[k]
    } else {
      remappedObj[k] = eventParameters[k]
    }
  })
  return remappedObj
}

export const isIOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const getAWBStatusText = (status) => {
  switch (status) {
    case 'unavailable':
      return 'Unavailable'
    case 'pending':
      return 'Pending'
    case 'waiting':
      return 'Waiting'
    case 'created':
      return 'Created'
    case 'canceled':
      return 'Canceled'
    case 'failed':
      return 'Failed'
  }
}

export const getRoleCodeText = (roleCode) => {
  switch (roleCode) {
    case 'retailer':
      return 'Seller'
    case 'fulfillment_provider':
      return 'Fulfillment Provider'
    default:
      return roleCode
  }
}

export const generateRandomString = (limit = 10) =>
  customAlphabet(
    '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    limit
  )

export const getValueOfData = (data, keyToFind) => {
  let result

  function traverse(obj) {
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === 'object' && key !== keyToFind) {
        traverse(obj[key])
      } else if (key === keyToFind) {
        result = obj[key]
      }
    }
  }

  traverse(data)

  return result
}

export const isNumber = (value) => {
  return Number.isFinite(value)
}

function qrStringToUrl(qrString) {
  qrString = encodeURIComponent(qrString)
  return `https://api.qrserver.com/v1/create-qr-code/?color=000000&bgcolor=FFFFFF&data=${qrString}&qzone=1&margin=0&size=400x400&ecc=L`
}

export const mapPgPaymentInfo = (order, isMobile = false) => {
  let qrString = ''
  let qrImageUrl = ''
  let vaNumber = ''
  let vaAccountHolder = ''
  let invoiceUrl = ''

  switch (order.epayment_provider) {
    case 'duitku':
      qrString = order.pg_payment_info?.qrString
      qrImageUrl = qrString ? qrStringToUrl(qrString) : ''
      vaNumber = order.pg_payment_info?.vaNumber
      vaAccountHolder = ''
      invoiceUrl = order.pg_payment_info?.paymentUrl
      break

    case 'midtrans':
      qrString = ''
      qrImageUrl = ''
      vaNumber = ''
      invoiceUrl = ''
      break

    default:
      qrString = !order.pg_payment_info?.metadata?.user_id
        ? order.pg_payment_info?.qr_string
        : order.pg_payment_info?.payment_method?.qr_code?.channel_properties
            ?.qr_string || ''
      qrImageUrl = qrString ? qrStringToUrl(qrString) : ''
      vaNumber = !order.pg_payment_info?.metadata?.user_id
        ? order.pg_payment_info?.account_numbner
        : order.pg_payment_info?.payment_method?.virtual_account
            ?.channel_properties?.virtual_account_number ||
          order.pg_payment_info?.payment_method?.over_the_counter
            ?.channel_properties?.payment_code ||
          ''
      vaAccountHolder = !order.pg_payment_info?.metadata?.user_id
        ? ''
        : order.pg_payment_info?.payment_method?.virtual_account
            ?.channel_properties?.customer_name ||
          order.pg_payment_info?.payment_method?.over_the_counter
            ?.channel_properties?.customer_name ||
          ''
      invoiceUrl =
        order.pg_payment_info?.invoice_url ||
        order.pg_payment_info?.redirect_url
      break
  }

  if (
    ['shopeepay', 'dana', 'ovo', 'linkaja'].includes(order.payment_method) &&
    !order.epayment_provider
  ) {
    if (isMobile) {
      invoiceUrl = order.pg_payment_info?.actions?.find(
        (x) => x.url_type === 'WEB' || x.url_type === 'DEEPLINK'
      )?.url
    } else {
      invoiceUrl = order.pg_payment_info?.actions?.find(
        (x) => x.url_type === 'MOBILE' || x.url_type === 'DEEPLINK'
      )?.url
    }
  }

  return {
    qrString,
    qrImageUrl,
    vaNumber,
    vaAccountHolder,
    invoiceUrl
  }
}

export const convertYoutubeURLToEmbed = (url) => {
  let fixedUrl = url
  if (fixedUrl.includes('/shorts/')) {
    fixedUrl = `https://youtu.be/${
      fixedUrl.split('/')[fixedUrl.split('/').length - 1]
    }`
  }
  /* eslint-disable */
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = fixedUrl.match(regExp)
  return match && match[2].length === 11
    ? `https://www.youtube.com/embed/${match[2]}`
    : null
}

export const getItemTypeName = (code) => {
  switch (code) {
    case 'digital':
      return 'Services or Digital'
    case 'physical':
      return 'Physical'
    case 'course':
      return 'Learning Management System'
    default:
      return 'Unknown'
  }
}
