import {
  encodeQueryData,
  initQueryParams,
  mapGettersHelper,
  mapMutationsHelper
} from '@/utils/helpers.js'

export const state = () => ({
  metaCustomer: {
    page: 1,
    pageSize: 25
  },
  customer: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit, state }, { query, isFirst = false } = {}) {
    if (isFirst) {
      commit('SET_CUSTOMER', [])
      commit('SET_META_CUSTOMER', {
        page: 1,
        pageSize: 25
      })
    }
    const { page, pageSize } = state.metaCustomer
    const initQuery = initQueryParams({
      page: isFirst ? 1 : page,
      page_size: isFirst ? 25 : pageSize,
      ...query
    })
    const queries = encodeQueryData(initQuery)
    const url = `/customer/?${queries}`
    try {
      const res = await this.$axios.$get(url)
      const allCustomer = [...state.customer, ...res.data.results]
      commit('SET_CUSTOMER', allCustomer)
      const nextMeta = {
        page: page + 1,
        pageSize
      }
      commit('SET_META_CUSTOMER', nextMeta)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchCount({ commit }, query) {
    try {
      const queries = encodeQueryData(query)
      const url = `/customer/count/?${queries}`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, idCustomer) {
    const url = `/customer/${idCustomer}/`
    try {
      const res = await this.$axios.$get(url)
      return res.data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    const { name, dateOfBirth, phone, email } = payload
    try {
      const res = await this.$axios.$post(`/customer/`, {
        name,
        date_of_birth: dateOfBirth,
        phone,
        email
      })
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    const { id, name, dateOfBirth, phone, email } = payload
    try {
      const res = await this.$axios.$patch(`/customer/${id}/`, {
        name,
        date_of_birth: dateOfBirth,
        phone,
        email
      })
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  },

  async upload({ commit }, file) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const res = await this.$axios.$post(`/upload-customers/`, formData)
      return res
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error('Bad Credentials')
        } else if (error.response.status === 502) {
          throw new Error('Network Error')
        }
      }
      throw error
    }
  }
}
