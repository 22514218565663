import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  tiktokPixels: [],
  tiktokEventsOnLoad: [],
  tiktokEventsOnSubmit: [],
  isAllTiktokPixelsLoaded: false,
  standardEvents: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, queries = {}) {
    const { page = 1, search = '' } = queries
    try {
      const resPixels = await this.$axios.$get(
        `/tiktok-pixel/?page=${page}&name=${search}`
      )
      commit('SET_TIKTOK_PIXELS', resPixels.data.results)
      commit('SET_IS_ALL_TIKTOK_PIXELS_LOADED', resPixels.data.next === null)
      return resPixels
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllEvents({ commit }) {
    try {
      const res = await this.$axios.$get(`/tiktok-standard-event/`)
      commit('SET_STANDARD_EVENTS', res.data.results)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById(_, id) {
    try {
      const resFetchById = await this.$axios.$get(`/tiktok-pixel/${id}/`)
      return resFetchById
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData(_, payload) {
    const { name, pixelId, conversionToken, testEventCode } = payload
    try {
      const resStoreData = await this.$axios.$post('/tiktok-pixel/', {
        name,
        pixel_id: pixelId,
        conversion_token: conversionToken,
        test_event_code: testEventCode
      })
      return resStoreData
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update(_, payload) {
    const { id, name, pixelId, conversionToken, testEventCode } = payload
    try {
      const resUpdate = await this.$axios.$put(`/tiktok-pixel/${id}/`, {
        name,
        pixel_id: pixelId,
        conversion_token: conversionToken,
        test_event_code: testEventCode
      })
      return resUpdate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete(_, id) {
    try {
      await this.$axios.$delete(`/tiktok-pixel/${id}/`)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
