import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  wabaAccounts: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async retrieve({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/business/waba/${id}`
      const res = await this.$axios.$get(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async set({ commit }, payload) {
    const { wabaId, phoneNumberId, code } = payload
    try {
      const url = `${this.$config.apiUrlV2}/business/waba/login/`
      const res = await this.$axios.$patch(url, {
        waba_id: wabaId,
        phone_number_id: phoneNumberId,
        code
      })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async registerPhone({ commit }, payload) {
    const { id, pin } = payload
    try {
      const url = `${this.$config.apiUrlV2}/business/waba/${id}/register-phone`
      const res = await this.$axios.post(url, { pin })
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllAccounts({ commit, state }, { query, isFirst = false } = {}) {
    commit('SET_WABA_ACCOUNTS', [])
    const url = `${this.$config.apiUrlV2}/business/waba`
    try {
      const res = await this.$axios.$get(url)
      const results = [...state.wabaAccounts, ...res.data]
      commit('SET_WABA_ACCOUNTS', results)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async remove({ commit }, id) {
    try {
      const url = `${this.$config.apiUrlV2}/business/waba/${id}/`
      const res = await this.$axios.delete(url)
      return res
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
